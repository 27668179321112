import { useState, useEffect } from "react";
import { formatDate } from "../lib/utils";
import Link from "next/link";

const SearchItem = props => {
  const { data } = props;
  const [tempSlug, setTempSlug] = useState("");

  useEffect(() => {
    switch (data.resource_page.slug) {
      case 'gallery':
      case 'toolkit-compilations':
        setTempSlug(`/${data.resource_page.slug}?id=${data.id}`);
        break;

      default:
        setTempSlug(`/resources/${data.resource_page.slug}?id=${data.id}`);
        break;
    }
  }, [data]);

  return (
    <>
      <Link href={tempSlug}>
        <a>
          <div className="hover:bg-brand-blue-lighter hover:bg-opacity-5 px-3 py-1 -mx-3 rounded-md">
            <div className="mb-1 leading-tight">
              <h3 className="text-sm text-gray-700 font-medium">
                {data.translation.title}
              </h3>
              <div className="text-sm text-brand-blue-lighter">
                <span>{data.resource_page.translation.name}</span> |{" "}
                <span>{formatDate(data.published_at)}</span>
              </div>
            </div>
          </div>
        </a>
      </Link>
    </>
  );
};

export default SearchItem;
