import React from "react";
import SpinnerIcon from "./SpinnerIcon";

const Spinner = (props) => {
  const { data } = props;
  // console.log(data.size);
  return (
    <div className="Spinner">
      <SpinnerIcon data={data} />
    </div>
  );
};

export default Spinner;
