import React from "react";

const SpinnerIcon = (props) => {
  const { data } = props;
  return (
    <svg
      style={{ background: "none" }}
      // style="margin: auto; background-color: rgb(255, 255, 255); display: block; shape-rendering: auto; background-position: initial initial; background-repeat: initial initial;"
      width={data.size}
      height={data.size}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <g transform="rotate(0 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-1.0975609756097562s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(4.390243902439025 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-1.0840108401084012s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(8.78048780487805 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-1.070460704607046s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(13.170731707317072 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-1.056910569105691s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(17.5609756097561 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-1.043360433604336s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(21.951219512195124 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-1.029810298102981s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(26.341463414634145 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-1.016260162601626s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(30.73170731707317 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-1.0027100271002711s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(35.1219512195122 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.989159891598916s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(39.51219512195122 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.9756097560975611s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(43.90243902439025 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.962059620596206s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(48.292682926829265 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.948509485094851s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(52.68292682926829 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.934959349593496s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(57.073170731707314 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.9214092140921409s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(61.46341463414634 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.907859078590786s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(65.85365853658537 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.8943089430894309s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(70.2439024390244 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.8807588075880759s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(74.63414634146342 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.8672086720867209s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(79.02439024390245 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.8536585365853658s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(83.41463414634147 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.8401084010840109s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(87.8048780487805 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.8265582655826559s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(92.1951219512195 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.8130081300813008s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(96.58536585365853 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.7994579945799458s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(100.97560975609755 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.7859078590785908s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(105.36585365853658 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.7723577235772358s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(109.7560975609756 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.7588075880758808s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(114.14634146341463 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.7452574525745258s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(118.53658536585365 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.7317073170731707s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(122.92682926829268 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.7181571815718157s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(127.3170731707317 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.7046070460704608s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(131.70731707317074 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.6910569105691057s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(136.09756097560975 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.6775067750677507s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(140.4878048780488 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.6639566395663957s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(144.8780487804878 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.6504065040650406s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(149.26829268292684 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.6368563685636857s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(153.65853658536585 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.6233062330623307s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(158.0487804878049 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.6097560975609756s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(162.4390243902439 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.5962059620596206s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(166.82926829268294 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.5826558265582656s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(171.21951219512195 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.5691056910569106s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(175.609756097561 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.5555555555555556s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.5420054200542006s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(184.390243902439 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.5284552845528455s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(188.78048780487805 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.5149051490514905s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(193.17073170731706 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.5013550135501356s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(197.5609756097561 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.48780487804878053s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(201.9512195121951 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.4742547425474255s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(206.34146341463415 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.46070460704607047s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(210.73170731707316 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.44715447154471544s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(215.1219512195122 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.43360433604336046s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(219.5121951219512 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.42005420054200543s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(223.90243902439025 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.4065040650406504s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(228.29268292682926 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.3929539295392954s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(232.6829268292683 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.3794037940379404s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(237.0731707317073 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.36585365853658536s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(241.46341463414635 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.3523035230352304s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(245.85365853658536 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.33875338753387535s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(250.2439024390244 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.3252032520325203s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(254.6341463414634 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.31165311653116534s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(259.0243902439024 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.2981029810298103s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(263.4146341463415 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.2845528455284553s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(267.8048780487805 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.2710027100271003s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(272.1951219512195 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.25745257452574527s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(276.5853658536585 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.24390243902439027s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(280.9756097560976 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.23035230352303523s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(285.3658536585366 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.21680216802168023s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(289.7560975609756 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.2032520325203252s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(294.1463414634146 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.1897018970189702s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(298.5365853658537 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.1761517615176152s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(302.9268292682927 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.16260162601626016s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(307.3170731707317 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.14905149051490515s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(311.7073170731707 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.13550135501355015s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(316.0975609756098 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.12195121951219513s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(320.4878048780488 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.10840108401084012s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(324.8780487804878 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.0948509485094851s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(329.2682926829268 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.08130081300813008s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(333.6585365853659 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.06775067750677508s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(338.0487804878049 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.05420054200542006s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(342.4390243902439 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.04065040650406504s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(346.8292682926829 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.02710027100271003s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(351.219512195122 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="-0.013550135501355014s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(355.609756097561 50 50)">
        <rect
          x="42.5"
          y="30.5"
          rx="7.5"
          ry="0.5"
          width="15"
          height="1"
          fill="#000000"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.1111111111111112s"
            begin="0s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
    </svg>
  );
};

export default SpinnerIcon;
