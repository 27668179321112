const ARTICLES_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/resources?resourcePageName=news-and-articles`;
const PUBLICATIONS_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/resources?resourcePageName=publications`;
const STATISTICS_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/resources?resourcePageName=cattle-statistics`;
const REGULATORY_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/resources?resourcePageName=regulatory-overview`;
const TOOLKIT_COMPILATIONS_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/resources?resourcePageName=toolkit-compilations`;
const STAKEHOLDERS_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/resources?resourcePageName=cattle-stakeholders`;
const FILTER_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/resource_pages/filters`;
const GALLERY_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/galleries?categories=`;
const GALLERY_FILTER_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/gallery_categories`;
const SEARCH_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/resources/search?q=`;
const FINMOD_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/finmod_business_models`;
const PREPARATION_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/preparation_types`;
const ONLINE_INVESTMENT_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/online_investments`;
const RESOURCES_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/resources`;
const HOME_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/homes`;
const HOME_SECTIONS_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/section_categories`;
const FILTER = "&filters=";
const SORTYEAR = "&sort[year]=";
const PAGE = "&page=";
const ID = "&id=";
const ITEMS_PER_PAGE = "&itemsPerPage=";

const apiProvider = {
  RESOURCES_URL,
  ARTICLES_URL,
  FILTER_URL,
  PUBLICATIONS_URL,
  STATISTICS_URL,
  REGULATORY_URL,
  TOOLKIT_COMPILATIONS_URL,
  STAKEHOLDERS_URL,
  GALLERY_URL,
  GALLERY_FILTER_URL,
  SEARCH_URL,
  FINMOD_URL,
  FILTER,
  SORTYEAR,
  PAGE,
  ID,
  ITEMS_PER_PAGE,
  PREPARATION_URL,
  ONLINE_INVESTMENT_URL,
  HOME_URL,
  HOME_SECTIONS_URL,
};

export default apiProvider;
