import Head from "next/head";
import SearchBar from "../SearchBar";
import NavBar from "../NavBar";
import Footer from "../Footer";
import useStore from "../../store/store";
import { useRouter } from 'next/router'

export default function AppLayout({
  children,
  title = null,
  meta_description = null
}) {
  const router = useRouter()
  const useLocale = useStore(state => state.locale);
  const { locale } = router
  let currentLang = locale == 'en' ? '' : '/' + locale

  return (
    <div className="relative">
      <Head>
        <title>{title || useLocale.title.default}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        { meta_description != null && <meta name="description" content={meta_description} /> }
        <link rel="icon" href="/favicon.ico"></link>
        {
          router.locales.map((locale) => {
            let hreflang = locale == 'en' ? 'x-default' : locale
            let lang = locale == 'en' ? '' : '/' + locale
              return (
                  <link
                      key={locale}
                      rel="alternate"
                      hrefLang={hreflang}
                      href={`${process.env.NEXT_PUBLIC_URL}${lang}${router.asPath}`}
                  />
              )
          })
        }
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_URL}${currentLang}${router.asPath}`} />
      </Head>

      <div className="fixed z-30 w-full">
        <SearchBar />
        <NavBar />
      </div>
      <div>{children}</div>
      <div className="w-full bg-white">
        <Footer />
      </div>
      <div id="modal" className="absolute z-50" />
    </div>
  );
}
