import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

// const Modal = ({ children, selector }) => {
//   const [mounted, setMounted] = useState(false);
//   useEffect(() => {
//     setMounted(true);
//     return () => setMounted(false);
//   }, [selector]);

//   return mounted
//     ? createPortal(children, document.querySelector(selector))
//     : null;
// };
const Modal = ({ open, onClose, children, selector, position = "start" }) => {
  const ModalContent = (
    <>
      <div
        className="fixed inset-0 z-10 overflow-y-auto overscroll-contain"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-end justify-center w-full min-h-screen px-4 pt-4 pb-20 text-center sm:p-0">
          <div
            onClick={onClose}
            className="fixed inset-0 transition-opacity bg-black bg-opacity-75"
            aria-hidden="true"
          ></div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="w-full px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-8">
            <div>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
  return open
    ? createPortal(ModalContent, document.querySelector(selector))
    : null;
};

export default Modal;
